import React, { Component } from 'react'
import {
    Cascader,
    Input,
    Button,
    Modal,
    Form,
    Upload,
    Select,
    Checkbox,
    message
} from 'antd'
import { populationList, storeSize, corporateWelfare, province, companyEditeSave } from '../../api'
import { PlusOutlined } from '@ant-design/icons'

import "./index.less"

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

let config = { path: 'companyLogo' }
let OMS = ''
if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
} else {
    OMS = 'https://test.fanwoon.com/oms'
}

let uploadPath = `${OMS}/upload/uploadImage`



class Company extends Component {

    formRef = React.createRef()

    constructor() {
        super(...arguments)
        this.state = {
            company: {
                gallery: []
            },
            showEdit: false, //是否显示编辑页面
            population: [], //公司规模
            storeSizeArray: [], // 门店规模
            corporateWelfareArray: [], //门店福利
            provinceMapList: [], //省市区

            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: [],
            imageUrl: ''
        }
    }

    componentWillMount() {
        this.init()
    }
    componentDidMount() {
        this.onDetail()
    }

    init() {
        this.onPopulationList()
        this.onStoreSize()
        this.onCorporateWelfare()
        this.handleProvinceMap()
    }

    //修改公司信息
    async onCompanyEditeSave(data) {
        let { parent } = this.props
        let res = await companyEditeSave(data)
        if (res.code === 0) {
            message.success('公司信息信息修改!')
            parent.onTaggleEdit()
            parent.init()
            return
        }
        message.error('公司信息修改' + res.message)
    }

    //加载公司详情
    onDetail() {
        let { company } = this.props
        if (company['gallery']) {
            company['gallery'] = this.handlePic(company['gallery'])
        }


        for (let i in company) {
            this.formRef.current.setFieldsValue({
                    [i]: company[i] }) //动态给form赋值 
        }
        this.formRef.current.setFieldsValue({ city: [company.provinceId, company.cityId, company.districtId] })
        this.setState({ company })
    }

    /**
     * @description 获取省市区各个的编号以及名称
     * @param {*} cityMap 
     */
    handleGetCityInfo(cityMap) {
        let { provinceMapList } = this.state
        let provinceId = ''
        let province = '' //省
        let cityId = '' //市id
        let city = ''
        let districtId = ''
        let district = ''

        provinceMapList.forEach((p, index) => {
            if (p.id === cityMap[0]) {
                province = p.name
                provinceId = p.id
            }
            p.children.forEach((c, cindex) => {
                if (c.id === cityMap[1]) {
                    city = c.name
                    cityId = c.id
                }
                c.children.forEach((d, dindex) => {
                    if (d.id === cityMap[2]) {
                        district = d.name
                        districtId = d.id
                    }
                })
            })
        })
        return { provinceId, province, cityId, city, districtId, district }
    }

    //处理公司图片组
    handlePic(arry) {
        return arry.map(item => {
            return {
                uid: (Math.random() * 10000).toFixed(0),
                name: '公司图片展示',
                status: 'done',
                url: item,
            }
        })
    }

    //省市区
    async handleProvinceMap() {
        let res = await province()
        this.setState({ provinceMapList: res.data })
    }

    //门店福利
    async onCorporateWelfare() {
        let res = await corporateWelfare()
        let fl = res.data.map(item => item.name)
        this.setState({ corporateWelfareArray: fl })
    }

    //门店规模
    async onStoreSize() {
        let res = await storeSize()
        this.setState({ storeSizeArray: res.data })
    }

    //公司规模
    async onPopulationList() {
        let res = await populationList()
        this.setState({ population: res.data })
    }

    //打开或关闭编辑页面
    onTaggleEdit() {
        let { showEdit } = this.state
        this.setState({ showEdit: !showEdit })
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    }

    //完成公司信息修改
    onFinish(values) {
        let { company } = this.state
        values['id'] = company.id
        values['logo'] = company.logo
        values['gallery'] = this.handleGallery(company.gallery)
        let citys = this.handleGetCityInfo(values.city)
        delete values['city']
        if (values['corporateWelfare']) {
            values['corporateWelfare'] = JSON.stringify(values['corporateWelfare'])
        }
        values = Object.assign(values, citys)
        this.onCompanyEditeSave(values)
    }

    //处理公司预览图
    handleGallery(gallery) {
        let array = gallery.map(item => item.url)
        return array ? array.join(",") : ""
    }

    companyLogoChange(e) {}

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            let res = info.file.response.data
            let { company } = this.state
            company['logo'] = res
            this.setState({
                loading: false,
                company
            })
        }
    }

    //处理公司预览图
    handleChange2 = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        let res = info.file.response.data
        let picObj = {
            uid: (Math.random() * 10000).toFixed(0),
            name: '公司图片展示',
            status: 'done',
            url: res,
        }
        let { company } = this.state
        if (!company.gallery) {
            company.gallery = []
        }
        company['gallery'].push(picObj)
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            this.setState({
                loading: false,
                company
            })
        }
    }

    //移除公司预览图图片组
    onRemove(e) {
        let { company } = this.state
        company.gallery.forEach((item, index) => {
            if (item.uid === e.uid) {
                company.gallery.splice(index, 1)
            }
        })
        this.setState({ company })
    }

    onBack() {
        let { parent } = this.props
        parent.onTaggleEdit()
        parent.init()
    }

    render() {
        const {
            company,
            previewVisible,
            previewImage,
            previewTitle,
            population,
            storeSizeArray,
            corporateWelfareArray,
            provinceMapList
        } = this.state


        const uploadButton = ( 
            <div>
            <PlusOutlined />
            <div style = {{ marginTop: 8 } } > 上传 </div> 
            </div>
        )

        return ( 
            <Form ref = { this.formRef }
            onFinish = { this.onFinish.bind(this) }
            className = 'edite-company-box' >

            <div className = 'flex-item company-header-box' >
            <div className = 'company-logo' >
            <Upload name = 'file'
            headers = { config }
            action = { uploadPath }
            listType = "picture-card"
            showUploadList = { false }
            beforeUpload = { beforeUpload }
            onChange = { this.handleChange }
            withCredentials = { true } >
            {
                company.logo ? < img src = { company.logo }
                alt = "avatar"
                style = {
                    { width: '100%' } }
                /> : uploadButton} 
                </Upload>       { /* <Image className='company-pic' src={company.logo} /> */ } 
                </div> 
                <div className = 'company-info-box' >
                    <div className = 'company-name' > { company.name } </div> 
                    <div className = 'company-sub-name' >
                        <Form.Item label = "线下门店牌匾名"
                        name = "shortName" >
                        <Input placeholder = "翻腕地产(静安店)" / >
                        </Form.Item> 
                    </div> 
                </div> 
                </div> 
                <div className = 'carousel-item' >
                <div > 门店展示（ 最多5张图片） </div> 
                <Upload
                name = 'file'
                headers = { config }
                action = { uploadPath }
                listType = "picture-card"
                fileList = { company.gallery }
                onPreview = { this.handlePreview }
                onChange = { this.handleChange2 }
                onRemove = { this.onRemove.bind(this) } >
                {!company.gallery ? uploadButton : company.gallery.length >= 5 ? null : uploadButton } 
                </Upload> 
                <Modal
                visible = { previewVisible }
                title = { previewTitle }
                footer = { null }
                onCancel = { this.handleCancel } >
                <img alt = "example"
                style = {
                    { width: '100%' } }
                src = { previewImage }
                /> 
                </Modal> 
                </div> 
                <div className = 'company-detail-box' >
                <Form.Item label = "门店福利"
                name = "corporateWelfare" >
                <Checkbox.Group
                options = { corporateWelfareArray }
                /> 
                </Form.Item> 
                </div> 
                <div className = 'company-detail-box' >
                <div className = 'company-title' > 门店介绍 </div> 
                <div>
                <Form.Item name = "introduction" >
                <Input.TextArea rows = { 8 }
                /> 
                </Form.Item> 
                </div> 
                </div> 
                <div className = 'company-detail-box' >
                <div className = 'company-title' > 地址 </div> 
                <div className = 'company-city-box' > { /* [company.provinceId, company.cityId, company.districtId] */ } 
                <div>
                <Form.Item name = "city" >
                <Cascader
                fieldNames = {
                    { label: 'name', value: 'id', children: 'children' } }
                options = { provinceMapList }
                /> 
                </Form.Item> 
                </div> 
                <div>
                <Form.Item name = "hqAdd" >
                <Input />
                </Form.Item> 
                </div> 
                </div> 
                </div> 
                <Form.Item >
                <Button type = "primary"
                htmlType = "submit" > 提交 </Button> 
                <Button className = 'cancel-btns'
                onClick = { this.onBack.bind(this) } > 取消 </Button> 
                </Form.Item> 
                </Form>
            )
        }
    }

    export default Company