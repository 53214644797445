import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../assets/css/header.less'
import { Button, message, Modal, Image, Form, Input, Radio, Select, Upload } from 'antd'
import { setPassword, accountLogout, accountUpdate, remaining } from '../api'
import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { SET_USER } from '../utils/constant'
import Qrcode from 'qrcode-react'
import ImgCrop from 'antd-img-crop'
import { initAccount } from '../store/action'

const avatar = 'http://img.fanwoon.com/avatar.png'
const logo = 'https://img.fanwoon.com/new_logo_small.png'
const job = 'https://img.fanwoon.com/guide_new.png'

const { confirm } = Modal

React.Component.prototype.$moeny = 1000;

let config = { path: 'avatar' }
let OMS = ''
if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
} else {
    OMS = 'https://test.fanwoon.com/oms'
}

let uploadPath = `${OMS}/upload/uploadImage`

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class Header extends Component {

    formRef = React.createRef()
    formRef2 = React.createRef()

    constructor(props) {
        super(props)
        this.state = {
            userInfo: {},
            visible: false,
            visible2: false, //控制修改密码弹框
            visible3: false, //公司分享弹框
            avatarPic: '', //用户头像
            menu: [{
                    id: (Math.random() * 10000).toFixed(0),
                    title: "职位",
                    path: "/job",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "招聘跟进",
                    path: "/follow",
                    active: false
                },
                {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "内推伯乐",
                    path: "/bole",
                    active: false
                },
                /* {
                    id: (Math.random() * 10000).toFixed(0),
                    title: "内推规则",
                    path: "/introversion",
                    active: false
                }, */
            ],
            sharePath: '',
            showMen: false, //是否显示头部
            money: 0,
        }
    }

    componentDidMount() {
        this.init()
        this._remaining()
        
    }

    async _remaining () {
        const res = await remaining();

        const { initMoney } = this.props
        localStorage.setItem('M', res.data)
        this.setState({ money: res.data })
        initMoney(res.data)
    }

    init() {
        let user = sessionStorage.getItem(SET_USER)
        if (user != null && user !== '') {
            user = JSON.parse(user)
            this.setState({ userInfo: user })
        }
        this.handleMenu()
        this.onShowHeader()
    }

    //检查是否显示头部
    onShowHeader() {
        let path = window.location.href
        let showMen = path.includes('/areement')
        this.setState({ showMen })
    }

    handleMenu() {
        let path = window.location.href
        let { menu } = this.state
        menu.forEach(item => {
            if (path.includes(item.path) || (item.path === "/follow" && path.includes("candidate"))) {
                item.active = true
            }
        })
    }

    async handleLogout() {
        confirm({
            title: '温馨提示',
            content: '你确定要退出吗?',
            cancelText: '取消',
            okText: '确定',
            onOk: () => {
                accountLogout().then(res => {
                    if (res.code === 0) {
                        message.success('退出成功!')
                        sessionStorage.clear()
                        let timeout = setTimeout(() => {
                            clearTimeout(timeout)
                            timeout = null
                            window.location.href = '/login'
                        }, 1000)
                    } else {
                        message.error('退出失败!')
                        return
                    }
                })
            }
        })
    }

    onClick = ({ key }) => {
        message.info(`Click on item ${key}`);
    }

    handleCancel() {

    }

    //变更我的角色.
    onChangeRadio(e) {
        let { value } = e.target
        let { userInfo } = this.state
        userInfo.jobGrade = value
        this.setState({ userInfo })
    }

    onChange = ({ fileList: newFileList }) => {
        this.setState({ fileList: newFileList })
    }

    //打开修改个人信息弹框
    onTagglePerson() {
        let { visible, userInfo } = this.state
        this.setState({ visible: !visible })
        if (!visible) {
            let timemout = setTimeout(() => {
                clearTimeout(timemout)
                timemout = null
                this.formRef2.current.setFieldsValue({ name: userInfo.name })
                this.formRef2.current.setFieldsValue({ jobGrade: userInfo.jobGrade })
                this.formRef2.current.setFieldsValue({ gender: userInfo.gender + '' })
            }, 50)
        }
    }

    //打开修改密码弹框 
    onTagglePwd() {
        let { visible2 } = this.state
        this.setState({ visible2: !visible2 })
        if (this.formRef.current) {
            this.formRef.current.resetFields()
        }
    }

    //企业信息
    onGoCompanyPage() {
        window.location.pathname = '/company'
    }

    //完成修改密码
    async onFinish(values) {
        delete values["newPassword2"]
        let res = await setPassword(values)
        if (res.code === 0) {
            message.success('密码成功!')
            this.onTagglePwd()
        } else {
            message.error(res.message)
        }
        //res.code === 0 ?  message.success('密码成功!') : message.error(res.message)     
    }

    //完成修改个人信息
    async onFinish2(values) {
        let { userInfo, avatarPic } = this.state

        let data = {
            ...values,
            jobGrade: userInfo.jobGrade,
            id: userInfo.id,
            code: 99999
        }
        if (avatarPic) {
            data['imgUrl'] = avatarPic
        }
        let res = await accountUpdate(data);
        if (res.code === 0) {
            message.success('个人信息修改!')
            userInfo.name = values.name
            userInfo.gender = values.gender
            userInfo.imgUrl = avatarPic
            sessionStorage.setItem(SET_USER, JSON.stringify(userInfo))
            this.setState({ visible: false })
            this.init()
        } else {
            message.error(res.message)
        }
    }

    checkOld = (rule, value, callback) => {

        let newPassword = this.formRef.current.getFieldValue('newPassword')
        let newPassword2 = this.formRef.current.getFieldValue('newPassword2')
        if (!value) return callback('密码不能为空!')
        if (newPassword2 && newPassword !== newPassword2) return callback('两次确认密码不一致')
        let patrn = /^[0-9A-Za-z]{8,}$/
        return patrn.test(value) ? callback() : callback('请输入8位字母+数字组合')
    }

    checkPwdOld = (rule, value, callback) => {
        if (!value) return callback('密码不能为空!')
        return callback()
    }

    //验证密码
    checkPwd = (rule, value, callback) => {
        if (!value) return callback('密码不能为空!')
        let patrn = /^[0-9A-Za-z]{8,}$/
        return patrn.test(value) ? callback() : callback('请输入8位字母+数字组合')
    }

    checkPwd2 = (rule, value, callback) => {
        let newPassword = this.formRef.current.getFieldValue('newPassword')
        let newPassword2 = this.formRef.current.getFieldValue('newPassword2')
        if (!value) return callback('密码不能为空!')
        if (newPassword2 && newPassword !== newPassword2) return callback('两次确认密码不一致')
        let patrn = /^[0-9A-Za-z]{8,}$/
        return patrn.test(value) ? callback() : callback('请输入8位字母+数字组合')
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            let res = info.file.response.data
            this.setState({
                loading: false,
                avatarPic: res
            })
        }
    }


    //打开分享公司二维码
    onOpenShareCompany() {
        let user = sessionStorage.getItem(SET_USER)
        user = JSON.parse(user)
        let path = `https://qr.tueneitui.com/companyId?companyId=${user.companyId}`
        this.setState({ visible3: true, sharePath: path })
    }

    handleCancel2() {
        this.setState({
            visible3: false,
            sharePath: ''
        })
    }

    //跳转到对应的页面
    onBack() {
        let { showMen } = this.state
        showMen ? window.location.href = '/login' : window.location.href = '/job'
    }

    onAmount = () => {
        window.location.href = '/amount'
    }

    /**
     * 右部菜单
     */
    rightMenuUI () {
        const { userInfo, money } = this.state;
        const { state } = this.props
        return (
            <div className = 'menu-right-box' >
                <div>
                    <Image className="pic-money-ico" src="https://img.fanwoon.com/yue%402x.png"  />
                    <span className="font-money" onClick={this.onAmount.bind(this)}>可用余额：<span className="font-underline font-money-num">{Number(!state.store.money ? 0 : state.store.money).toFixed(2)}</span></span>
                </div>
                <Button className = 'm-r-16'
                onClick = { this.onOpenShareCompany.bind(this) } > 邀请员工内推 </Button>  
                <div className = 'default-color' >
                    <div className = "avatar-info-box"
                        onClick = { e => e.preventDefault() } >
                        <Image className = 'avatar-box'
                        src = {!userInfo.imgUrl ? avatar : userInfo.imgUrl }
                        /> 
                        <span className = 'name' > { userInfo.name } </span > <DownOutlined />
                        <div className = 'menu-box-list' >
                            <div className = "menu-item"
                            onClick = { this.onTagglePerson.bind(this) } > 个人信息 </div>  
                            <div className = "menu-item"
                            onClick = { this.onGoCompanyPage.bind(this) } > 公司信息 </div>  
                            <div className = "menu-item"
                            onClick = { this.onTagglePwd.bind(this) } > 修改密码 </div>  
                            <div className = "menu-item"
                            onClick = { this.handleLogout.bind(this) } > 退出登录 </div>  
                        </div> 
                    </div>  
                </div> 
            </div>
        )
    }

    render() {
        const { userInfo, visible, visible2, avatarPic, menu, visible3, sharePath, showMen } = this.state
        const uploadButton = ( <div>
            <PlusOutlined / >
            <div style = {
                { marginTop: 8 }
            } > 上传 </div> </div>
        )

        return ( 
            <div className='fixBox'>
                <div className = 'header-box' >
                <div className = 'left-box' >
                    <div className = 'logo-box'
                    onClick = { this.onBack.bind(this) } >
                    <img alt = "logo"
                    src = "https://img.fanwoon.com/new_logo.png" />
                    </div> 
                    {
                    showMen ? null :
                    menu.map((item, index) => {
                        return ( < a className = { `m-lr-8 ${item.active ? 'active-menu' : ''}` }
                            href = { item.path }
                            key = { index } > { item.title } </a>
                        )
                    })
                } </div> {
                showMen ? null : ( this.rightMenuUI() )
            }

    </div>

    <Modal 
        title = "修改密码"
        visible = { visible2 }
        onCancel = { this.onTagglePwd.bind(this) }
        footer = { null } >
        <Form name = "basic" ref = { this.formRef } onFinish = { this.onFinish.bind(this) } >
            <Form.Item label = "原始密码"
                name = "oldPassword"
                rules = {
                    [{ validator: this.checkPwdOld }]
                } >
                <Input.Password placeholder = '请输入您当前的密码' />
            </Form.Item> 
            <Form.Item label = "&nbsp;&nbsp;新密码"
                name = "newPassword"
                rules = {
                        [{ validator: this.checkPwd }]
                    } >
                <Input.Password placeholder = '8位以上字母+数字组合' />
            </Form.Item> 
            <Form.Item label = "确认密码"
                name = "newPassword2"
                rules = {
                        [{ validator: this.checkPwd2 }]
                    } >
                <Input.Password placeholder = '请再输入一次' />
            </Form.Item>  
            <Form.Item >
                <Button type = "primary" htmlType = "submit"className = "reset-sub-btn" > 提交 </Button>  
                <Button onClick = { this.onTagglePwd.bind(this) } > 取消 </Button>  
            </Form.Item > 
       </Form>  
    </Modal> 
    <Modal title = "编辑个人信息"
    visible = { visible }
    onCancel = { this.onTagglePerson.bind(this) }
    footer = { null } >
        <Form name = "basic"
    ref = { this.formRef2 }
    onFinish = { this.onFinish2.bind(this) } >
        <Form.Item label = "线下门店牌匾名"
    className = 'edite-user-item' > { userInfo.shortName } </Form.Item>  
    <div className = 'name-info-box'>
        <div className = 'left-box'>
        <Form.Item label = "姓名"
    name = "name"
    rules = {
        [{ required: true, message: '请输入你的姓名!' }]
    }
    className = 'edite-user-item' >
        <Input />
        </Form.Item>

    </div>  
    <div className = 'right-box' >
        <Form.Item name = "gender">
        <Select>
        <Select.Option value = '1' > 先生 </Select.Option>  
        <Select.Option value = '0' > 女士 </Select.Option>  
        </Select> </Form.Item>  </div> 
    </div>

    <Form.Item label = "角色"
    className = 'edite-user-item' > { userInfo.system ? '管理员' : '普通用户' } </Form.Item> 
     <Form.Item label = "手机"
    className = 'edite-user-item' > { userInfo.phone } </Form.Item>  
    <Form.Item>
        <div>
        <div> 我是 </div>  <div>
        <Radio.Group onChange = { this.onChangeRadio.bind(this) }
            defaultValue = { userInfo.jobGrade } >
                <Radio value = "店长" > 店长 </Radio>  
                <Radio value = "区域/商圈经理" > 区域 / 商圈经理 </Radio>  
                <Radio value = "招聘人员" > 招聘人员 </Radio> 
                <Radio value = "其他" > 其他 </Radio>  </Radio.Group > 
                </div>  </div > </Form.Item> <Form.Item >
                <ImgCrop rotate >
                <Upload name = 'file'
            headers = { config }
            action = { uploadPath }
            listType = "picture-card"
            showUploadList = { false }
            beforeUpload = { beforeUpload }
            onChange = { this.handleChange }
            withCredentials = { true } > {
                avatarPic ? < img src = { avatarPic }
                alt = "avatar"
                style = {
                    { width: '100%' }
                }
                /> : uploadButton} </Upload >
                </ImgCrop>  </Form.Item > <Form.Item >
                <Button type = "primary"
                htmlType = "submit" > 确认 </Button>  </Form.Item ></Form> 
                </Modal>
                <Modal
                title = "将职位分享同事，让他们帮你内推吧！"
                visible = { visible3 }
                footer = { null }
                onCancel = { this.handleCancel2.bind(this) } >
                <div className = 'share-box' >
                <div>
                <Qrcode
                size = { 150 }
                value = { sharePath }
                logo = { logo }
                logoWidth = { 50 }
                logoHeight = { 50 }
                />  
                <div> 步骤一： 打开微信扫一扫 </div>  </div > <div> 
                <Image className = 'job-pic'
                src = { job }
                />  
                <div> 第二步， 点击「 邀请朋友应聘」 </div> </div > </div> </Modal> </div>
            )
            }
        }


        function mapStateToProps(state) {
             return { state: state};
        }

        function mapDispatchToProps(dispatch) {
            return {
                initMoney: moeny => dispatch(initAccount(moeny))
            }
        }
        const App =  connect(
            mapStateToProps,
            mapDispatchToProps
        )(Header)

export default App