import http from '../utils/axios'
import axios from "axios"

axios.defaults.withCredentials = true

let OMS = ''
let TOB = ''
let base = 'https://test.fanwoon.com'

if (process.env.NODE_ENV === "development") {
    OMS = '/ips'
    TOB = '/api'
} else {
    OMS = base + '/oms'
    TOB = base + '/tob'
}
//  http://81.68.235.167:8383 

// 

/**
 * 申请奖励结算延长
 * @param {*} data 
 */
export const delayReward = (data) => {
    return new Promise((resolve, reject) => {
        const url = TOB + '/task/delay/apple/reward'
        axios.post(url, {data}).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

export const findBySearch = (data) => {
    return new Promise((resolve, reject) => {
        const url = TOB + '/account/oms/findBySearch'
        axios.post(url, {...data}).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 获取板块
 * @param {*} data 
 */
export const circlesParent = (data) => {
    return new Promise((resolve, reject) => {
        const url = OMS + '/dict/circles/parent'
        axios.post(url, {data}).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 根据code 获取城市
 * @param {*} data 
 */
export const cityGet = (data) => {
    return new Promise((resolve, reject) => {
        const url = OMS + '/dict/city/get'
        axios.post(url, {data}).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 充值信息
 * @param {*} data 
 */
export const transactionOrderInfo = (data) => {
    
    return new Promise((resolve, reject) => {
        const url = TOB + '/transaction/order/info'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 撤销退款
 * @param data 
 */
export const refundCancel = (data) => {
    
    return new Promise((resolve, reject) => {
        const url = TOB + '/transaction/refund/cancel'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 支付预览
 * @param {*} data 
 */
export const taskBrowse = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/task/browse'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 退款
 * @param {*} data 
 */
export const refundCreate = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/refund/create'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}


/**
 * 交易 - 支付订单 支付订单(name=JSAPI微信中调起支付，NATIVE扫码支付)
 * @param {*} data 
 */
export const orderPay = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/order/pay'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}


/**
 * 交易 - 创建订单
 * @param {*} data 
 */
export const orderCreate = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/order/create'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 订单列表
 */
export const orderList = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/order/list'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 退款明细
 * @param {*} data 
 */
export const refundList = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/refund/list'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * 交易列表
 * @param {*} data 
 */
export const transactionList = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/list'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}


/**
 * 余额查询
 */
export const remaining = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/transaction/remaining'
        axios.get(url, data, {
            headers: {'content-type': 'application/x-www-form-urlencoded'}
        }).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 修改或新增企业
 * @param {data} 
 */
export const companyEditeSave = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/company/save'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 变更用户信息
 * @param {data} 
 */
export const jobDetail = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/job/detail'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 变更用户信息
 * @param {data} 
 */
export const accountUpdate = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/account/update'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 上传图片
 * @param {newPassword} 新密码 
 * @param {oldPassword} 旧密码
 */
export const setPassword = (data) => {
    return new Promise((resolve, reject) => {
        let url = TOB + '/account/setPassword'
        axios.post(url, data).then(res => {
            resolve(res.data)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 省市区
 */
export const province = () => {
    return new Promise((resolve, reject) => {
        let url = OMS + '/dict/province/map'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 公司福利
 */
export const corporateWelfare = () => {
    return new Promise((resolve, reject) => {
        let url = OMS + '/dict/corporateWelfare/list'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 门店规模
 */
export const storeSize = () => {
    return new Promise((resolve, reject) => {
        let url = OMS + '/dict/storeSize/list'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 公司规模
 */
export const populationList = () => {
    return new Promise((resolve, reject) => {
        let url = OMS + '/dict/population/list'
        http('post', url).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 开启职位
 * @param {*} id 职位编号
 */
export const openJob = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/openJob'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 关闭职位
 * @param {*} id 职位编号
 */
export const closeJob = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/closeJob'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 手机+短信登录
 * @param {*} phone 手机号
 * @param {*} verify 短信码
 */
export const accountSmsLogin = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/smsLogin'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 更新职位
 */
export const jobUpdate = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/update'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 创建职位
 */
export const createJob = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/create'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 发送手机短信
 */
export const sendsms = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/sendSms'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 行业树
 */
export const industryList = () => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/function/list'
        http('post', path).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 退出
 * @param {*} data 表单数据
 */
export const accountLogout = () => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/logout'
        http('post', path).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 删除模板
 * @param {*} data 表单数据
 */
export const rewardDelete = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/reward/delete'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 保存内推奖励模板
 * @param {*} data 表单数据
 */
export const rewardSave = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/reward/save'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 获取推奖励模板
 * @param {*} data 分页
 */
export const rewardList = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/reward/list'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

// 

/**
 * @description 字典表
 */
export const jobHighLights = () => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/jobHighLights/list'
        http('post', path).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 省市区
 */
export const provinceMap = () => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/province/map'
        http('post', path).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 工作经验
 * @param {*} data
 */
export const workExpList = (data) => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/workExp/list'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 学历
 * @param {*} data
 */
export const educationList = (data) => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/education/list'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过名字查询公司
 * @param {*} data
 */
export const listCloseLikeName = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/listCloseLikeName'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过名字 + 只查看自己的 - 招聘中
 * @param {*} data
 */
export const listOpenLikeName = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/listOpenLikeName'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 招聘中
 * @param {*} data
 */
export const listOpen = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/listOpen'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 校验手机是否已被注册 0没注册
 * @param {*} data
 */
export const checkRegisterPhone = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/checkRegisterPhone'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 注册账号
 * @param {*} data
 */
export const register = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/register'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 *  @description 生成图形验证嘛
 */
export const getValidateCode = TOB + '/account/getValidateCode'

/**
 * @description 学历
 * @param {*} data
 */
export const edcation = (data) => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/dict/education/list'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}

/**
 * @description 通过名字查询职位 + 分页查询
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const listlikeName = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/job/listJobLikeName'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}


/**
 * @description 查询企业信息 + 分页查询
 * @param {*} account 账号
 * @param {*} pwd 密码
 */
export const listLikenameByCompany = (data) => {
    return new Promise((resolve, reject) => {
        let path = OMS + '/company/listLikeName'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}



/**
 * @description 账号密码登录
 * @param {*} data
 */
export const accountLogin = (data) => {
    return new Promise((resolve, reject) => {
        let path = TOB + '/account/login'
        http('post', path, data).then(res => {
            resolve(res)
        }, error => {
            reject(error)
        })
    })
}